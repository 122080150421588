import produce from "immer";
import {
  CLEAR_DEBIT_NOTE_STATE,
  CLEAR_SINGLE_DEBIT_NOTE,
  FETCH_DEBIT_NOTE_LIST_FAILED,
  FETCH_DEBIT_NOTE_LIST_PROGRESS,
  FETCH_DEBIT_NOTE_LIST_SUCCESS,
  FETCH_SINGLE_DEBIT_NOTE_FAILED,
  FETCH_SINGLE_DEBIT_NOTE_PROGRESS,
  FETCH_SINGLE_DEBIT_NOTE_SUCCESS,
  IDebitNodeActions,
} from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultDebitNote, defaultDebitNoteState } from "./defaultState";

export const debitNoteReducer = (
  state: IStoreState["debitNote"] = defaultDebitNoteState,
  action: IDebitNodeActions,
) => {
  switch (action.type) {
    case FETCH_DEBIT_NOTE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_DEBIT_NOTE_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_DEBIT_NOTE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_SINGLE_DEBIT_NOTE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.debitNote.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_DEBIT_NOTE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.debitNote.loading = LoadState.Loaded;
        draftState.debitNote.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_DEBIT_NOTE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.debitNote.loading = LoadState.Failed;
        draftState.debitNote.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_DEBIT_NOTE: {
      const newState = produce(state, (draftState) => {
        draftState.debitNote.loading = LoadState.NotLoaded;
        draftState.debitNote.data = defaultDebitNote;
        draftState.debitNote.error = null;
      });
      return newState;
    }
    case CLEAR_DEBIT_NOTE_STATE: {
      return defaultDebitNoteState;
    }
    default: {
      return state;
    }
  }
};
