import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ICreditNote } from "./types";

//=============================== Fetch Multiple Credit/Debit Nodes ===============================
export const FETCH_CREDIT_NOTE_LIST_PROGRESS =
  "FETCH_CREDIT_NOTE_LIST_PROGRESS";
export const FETCH_CREDIT_NOTE_LIST_SUCCESS = "FETCH_CREDIT_NOTE_LIST_SUCCESS";
export const FETCH_CREDIT_NOTE_LIST_FAILED = "FETCH_CREDIT_NOTE_LIST_FAILED";

export const fetchCreditNoteListProgress = () =>
  action(FETCH_CREDIT_NOTE_LIST_PROGRESS);
export const fetchCreditNoteListSuccess = (
  list: ICreditNote[],
  totalRecords: number,
) => action(FETCH_CREDIT_NOTE_LIST_SUCCESS, { list, totalRecords });
export const fetchCreditNoteListFailed = () =>
  action(FETCH_CREDIT_NOTE_LIST_FAILED);

export const fetchCreditNoteListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchCreditNoteListProgress());

      const res = await api.get(
        `/credit_debit_note/get-credit-debit-note${searchQuery}&status=ACTIVE&cancelled_status=TRUE`,
      );
      const data: ICreditNote[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchCreditNoteListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCreditNoteListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message || "Unknown Error Occurred",
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Fetch Single Credit/Debit Node ===============================
export const FETCH_SINGLE_CREDIT_NOTE_PROGRESS =
  "FETCH_SINGLE_CREDIT_NOTE_PROGRESS";
export const FETCH_SINGLE_CREDIT_NOTE_SUCCESS =
  "FETCH_SINGLE_CREDIT_NOTE_SUCCESS";
export const FETCH_SINGLE_CREDIT_NOTE_FAILED =
  "FETCH_SINGLE_CREDIT_NOTE_FAILED";

export const fetchCreditNoteProgress = () =>
  action(FETCH_SINGLE_CREDIT_NOTE_PROGRESS);
export const fetchCreditNoteSuccess = (data: ICreditNote) =>
  action(FETCH_SINGLE_CREDIT_NOTE_SUCCESS, { data });
export const fetchCreditNoteFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_CREDIT_NOTE_FAILED, { errorMessage });

export const fetchCreditNoteAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCreditNoteProgress());
      const res = await api.get(
        `/credit_debit_note/get-credit-debit-note?credit_debit_note_uuid=${uuid}`,
      );
      const data: ICreditNote[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchCreditNoteSuccess(data[0]));
      } else {
        dispatch(fetchCreditNoteFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchCreditNoteFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Single Credit/Debit Node  ===============================
export const upsertCreditNoteAsync =
  (
    payload: ICreditNote,
    onCallback: (isSuccess: boolean, invoiceUUID?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const {
        is_round_off,
        create_ts,
        insert_ts,
        combined_credit_debit_note_no,
        ...rest
      } = payload;

      const res = await api.post(
        "/credit_debit_note/upsert-credit-debit-note",
        rest,
      );

      onCallback(true, res.data.data.invoice_uuid);
      dispatch(
        showMessage({
          type: "success",
          message: "Invoice saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCreditNotePreviewAsync =
  (
    uuid: string,
    isIndianStyle: boolean,
    onCallback: (isSuccess: boolean, html?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/credit_debit_note/get-credit-debit-note-preview?credit_debit_note_uuid=${uuid}&isPreview=true&IndianStyle=${isIndianStyle}`,
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };

export const fetchCreditNoteDownloadPdfAsync =
  (
    uuid: string,
    isIndianStyle: boolean,
    onCallback: (isSuccess: boolean, html?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/credit_debit_note/get-credit-debit-note-preview?credit_debit_note_uuid=${uuid}&isPreview=false&IndianStyle=${isIndianStyle}`,
        {
          responseType: "arraybuffer",
        },
      );

      dispatch(saveLoaderCompleted());
      // Convert the PDF content into a Blob
      const blob = new Blob([res.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf"); // Set the filename for the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };

//=============================== Clear State ===============================
export const CLEAR_SINGLE_CREDIT_NOTE = "CLEAR_SINGLE_CREDIT_NOTE";
export const CLEAR_CREDIT_NOTE_STATE = "CLEAR_CREDIT_NOTE_STATE";
export const clearCreditNote = () => action(CLEAR_SINGLE_CREDIT_NOTE);
export const clearCreditNoteState = () => action(CLEAR_CREDIT_NOTE_STATE);
