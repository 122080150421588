import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../api/api";
import { authAPI } from "../../api/authAPI";

export enum userRoles {
  ADMIN = "ADMIN",
  None = "None",
}

export interface IAuth {
  userInfo: {
    token: string | null;
    name: string;
    user: {
      email: string;
      first_name: string;
      last_name: string;
      mobile_no: string;
      role_id: number;
      role_name: string;
      status: string;
      user_uuid: string;
    };
  };
  isLogin: boolean;
  login: (
    email: string,
    password: string,
    onSuccess: (isOTPPreview: boolean) => void,
    onError: (message: string) => void,
  ) => void;
  verifyOTP: (
    otp: string,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => void;
  forgotPassword: (
    email: string,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => void;
  setNewPassword: (
    email: string,
    otp: number,
    user_password: string,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => void;
  logout: () => void;
  addUserName: (name: string) => void;
}

const AuthContext = React.createContext<IAuth>({
  userInfo: {
    token: "",
    name: "",
    user: {
      email: "",
      first_name: "",
      last_name: "",
      mobile_no: "",
      role_id: -1,
      status: "ACTIVE",
      user_uuid: "",
      role_name: "",
    },
  },
  isLogin: false,
  login: () => {},
  verifyOTP: () => {},
  forgotPassword: () => {},
  setNewPassword: () => {},
  logout: () => {},
  addUserName: () => {},
});

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const [userInfo, setUserInfo] = React.useState<IAuth["userInfo"]>({
    token: null,
    name: "",
    user: {
      email: "",
      first_name: "",
      last_name: "",
      mobile_no: "",
      role_id: -1,
      status: "",
      user_uuid: "",
      role_name: "",
    },
  });
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const data = JSON.parse(auth);
      const date1 = new Date(data.date);
      const date2 = new Date();
      const diff = date2.getHours() - date1.getHours();
      if (diff <= 1) {
        setUserInfo(data);
      }
    }
    setLoading(false);
  }, []);

  const handleLogin = async (
    email: string,
    password: string,
    onSuccess: (isOTPPreview: boolean) => void,
    onError: (message: string) => void,
  ) => {
    try {
      const res = await api.post(authAPI.login, {
        email: email,
        password: password,
      });
      if (res.data.data.isOTPPreview) {
        setUserInfo({
          ...userInfo,
          user: {
            ...userInfo.user,
            email: email,
          },
        });
      } else {
        const userData: IAuth["userInfo"] = res.data.data;
        userData.name = "";
        if (userData.user.first_name) {
          userData.name = userData.user.first_name;
        }
        if (userData.user.last_name) {
          userData.name += " " + userData.user.last_name;
        }
        setUserInfo(userData);
        localStorage.setItem(
          "auth",
          JSON.stringify({ ...userData, date: new Date() }),
        );
      }
      onSuccess(res.data.data.isOTPPreview);
    } catch (error: any) {
      onError(error.response.data.message);
    }
  };

  const handleVerfiyOTP = async (
    otp: string,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => {
    try {
      const res = await api.post(authAPI.validateOtpGetToken, {
        email: userInfo.user.email,
        otp: Number(otp),
      });
      const userData: IAuth["userInfo"] = res.data.data;

      userData.name = "";
      if (userData.user.first_name) {
        userData.name = userData.user.first_name;
      }
      if (userData.user.last_name) {
        userData.name += " " + userData.user.last_name;
      }
      setUserInfo(userData);
      localStorage.setItem(
        "auth",
        JSON.stringify({ ...userData, date: new Date() }),
      );
      onSuccess();
    } catch (error: any) {
      onError(error.response.data.message);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    setUserInfo({
      token: "",
      name: "",
      user: {
        email: "",
        first_name: "",
        last_name: "",
        mobile_no: "",
        role_id: -1,
        status: "",
        user_uuid: "",
        role_name: "",
      },
    });
    window.open("/auth/login", "_self");
  };

  const handleForgotPassword = async (
    email: string,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => {
    try {
      await api.post("/authentication/forget-password", {
        email: email,
        otp: 0,
        user_password: null,
        action: "SEND",
      });
      onSuccess();
    } catch (error: any) {
      onError(error.response.data.message);
    }
  };

  const handleNewPassword = async (
    email: string,
    otp: number,
    user_password: string,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => {
    try {
      await api.post("/authentication/forget-password", {
        email: email,
        otp: otp,
        user_password: user_password,
        action: "RESET",
      });
      onSuccess();
    } catch (error: any) {
      onError(error.response.data.message);
    }
  };

  const addUserName = (name: string) => {
    setUserInfo({ ...userInfo, name: name });
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <AuthContext.Provider
      value={{
        userInfo: userInfo,
        isLogin: userInfo.token ? true : false,
        login: handleLogin,
        verifyOTP: handleVerfiyOTP,
        forgotPassword: handleForgotPassword,
        setNewPassword: handleNewPassword,
        logout: handleLogout,
        addUserName: addUserName,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
