import { LoadState } from "../../constants/enums";
import {
  IDebitNote,
  IDebitNoteItemType,
  IDebitNoteState,
} from "./debitNote.types";

export const defaultDebitNoteItem: IDebitNoteItemType = {
  product_uuid: "",
  product_name: "",
  customer_product_name: "",
  description: "",
  HSN_code: "",
  quantity: 0,
  unit: "",
  unit_price: 0,
  discount: 0,
  discount_amount: 0,
  taxable_amount: 0,
  tax_rate: 0,
  tax_amount: 0,
  total: 0,
  cess_rate: 0,
};

export const defaultDebitNote: IDebitNote = {
  debit_note_uuid: null,
  debit_note_type: "Goods Return",
  debit_note_date: "",
  document_type: "Export Debit (With/Without) IGST",
  debit_note_no: null,
  supplier_invoice_no: null,
  debit_issue_date: null,
  invoice_id: null,
  invoice_date: null,
  return_month: null,
  return_quarter: null,
  challan_no: null,
  challan_date: null,
  lr_no: null,
  eway_bill_no: null,
  delivery_options: null,
  dispatch_through: null,
  transport_id: null,
  transport_doc_no: null,
  transport_doc_date: null,
  vehicle_no: null,
  billing_company_uuid: "",
  billing_company_name: "",
  billing_company_branch_uuid: "",
  billing_company_branch_name: "",
  billing_company_branch_country: "",
  billing_company_branch_state: "",
  vendor_name: "",
  vendor_uuid: "",
  vendor_gstin: null,
  vendor_address_line1: null,
  vendor_address_line2: null,
  vendor_address_city: null,
  vendor_address_state: null,
  vendor_address_pincode: null,
  vendor_address_country: null,
  registration_type: null,
  place_of_supply: null,
  invoice_items: [defaultDebitNoteItem],
  taxable_amount: 0,
  total_tax: 0,
  tcs: 0,
  tcs_amount: 0,
  discount: 0,
  discount_amount: 0,
  total_amount: 0,
  total_value: 0,
  total_amount_in_words: null,
  term_and_condition: null,
  bank_uuid: null,
  bank_name: null,
  status: "ACTIVE",
};

export const defaultDebitNoteState: IDebitNoteState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  debitNote: {
    data: defaultDebitNote,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
