import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../AuthorizedRoute";
import { ALLOWED_MODULES } from "../../constants/enums";

//************************ Credit/Debit Node Imports ***************************/
const CreditNoteTable = Loadable(
  lazy(() =>
    import("../../views/creditNote/CreditNoteTable").then(
      ({ CreditNoteTable }) => ({
        default: CreditNoteTable,
      }),
    ),
  ),
);

const ManageCreditNote = Loadable(
  lazy(() =>
    import("../../views/creditNote/ManageCreditNote").then(
      ({ ManageCreditNote }) => ({
        default: ManageCreditNote,
      }),
    ),
  ),
);

export const creditDebitNodeRoutes = [
  {
    path: "/credit-note",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CREDIT_DEBIT_NOTE]}>
        <CreditNoteTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/credit-note/manage",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CREDIT_DEBIT_NOTE]}>
        <ManageCreditNote />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/credit-note/manage/:uuid",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CREDIT_DEBIT_NOTE]}>
        <ManageCreditNote />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/credit-note/duplicate/:uuid",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.CREDIT_DEBIT_NOTE]}>
        <ManageCreditNote isDuplicate={true} />
      </AuthorizedRoute>
    ),
  },
];
