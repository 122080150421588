import { LoadState } from "../../constants/enums";
import { defaultInvoice } from "../invoice";
import {
  IProformaInvoice,
  IProformaInvoiceState,
} from "./proformaInvoice.types";

export const defaultProformaInvoice: IProformaInvoice = {
  ...defaultInvoice,
  proforma_invoice_uuid: null,
  proforma_invoice_date: null,
  challan_date: null,
  challan_no: null,
  lr_no: null,

  delivery_options: null,
  dispatch_through: null,
  transport_doc_date: null,
  transport_doc_no: null,
  transport_id: null,
};
export const defaultProformaInvoiceState: IProformaInvoiceState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  proformaInvoice: {
    data: defaultProformaInvoice,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
