import { api } from "../../../api/api";

export const uploadFile = async (
  file: File | null,
  module_name: string,
  defaultReturn: string,
  asPayload: {
    [key: string]: string | number | null;
  },
) => {
  if (file) {
    const formdata = new FormData();
    formdata.append("files", file);
    formdata.append("module_name", module_name);
    formdata.append("as_payload", JSON.stringify(asPayload));
    const res = await api.post("/general/upload", formdata);
    const data: string[] = res.data.data;
    if (data.length > 0) {
      return data[0];
    }
  }
  return defaultReturn;
};
