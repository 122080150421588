import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { GOODS_RECEIVING_NOTE_ROUTE } from "./GoodsReceivingNote.constants";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { ALLOWED_MODULES } from "../../constants/enums";

//************************ Vendor Imports ***************************/
const GoodsReceivingNotesTable = Loadable(
  lazy(() =>
    import("./GoodsReceivingNotesTable").then(
      ({ GoodsReceivingNotesTable }) => ({
        default: GoodsReceivingNotesTable,
      }),
    ),
  ),
);

const ManageGoodsReceivingNote = Loadable(
  lazy(() =>
    import("./ManageGoodsReceivingNote").then(
      ({ ManageGoodsReceivingNote }) => ({
        default: ManageGoodsReceivingNote,
      }),
    ),
  ),
);

//************************ Product Routes ***************************/
export const goodsReceivingNoteRoutes = [
  {
    path: GOODS_RECEIVING_NOTE_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.GRN]}>
        <GoodsReceivingNotesTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${GOODS_RECEIVING_NOTE_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.GRN]}>
        <ManageGoodsReceivingNote />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${GOODS_RECEIVING_NOTE_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.GRN]}>
        <ManageGoodsReceivingNote />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${GOODS_RECEIVING_NOTE_ROUTE}/duplicate/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.GRN]}>
        <ManageGoodsReceivingNote isDuplicate={true} />
      </AuthorizedRoute>
    ),
  },
];
