import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { ALLOWED_MODULES } from "../../constants/enums";
import { AuthorizedRoute } from "../AuthorizedRoute";

//************************ Credit/Debit Node Imports ***************************/
const ProformaInvoiceTable = Loadable(
  lazy(() =>
    import("../../views/proformaInvoice/ProformaInvoiceTable").then(
      ({ ProformaInvoiceTable }) => ({
        default: ProformaInvoiceTable,
      }),
    ),
  ),
);

const ManageProformaInvoice = Loadable(
  lazy(() =>
    import("../../views/proformaInvoice/ManageProformaInvoice").then(
      ({ ManageProformaInvoice }) => ({
        default: ManageProformaInvoice,
      }),
    ),
  ),
);

export const proformaInvoiceRoutes = [
  {
    path: "/proforma-invoices",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PROFORMA_INVOICE]}>
        <ProformaInvoiceTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/proforma-invoices/manage",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PROFORMA_INVOICE]}>
        <ManageProformaInvoice />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/proforma-invoices/manage/:uuid",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PROFORMA_INVOICE]}>
        <ManageProformaInvoice />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/proforma-invoices/duplicate/:uuid",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PROFORMA_INVOICE]}>
        <ManageProformaInvoice isDuplicate={true} />
      </AuthorizedRoute>
    ),
  },
];
