export const get_module_name_path = {
  INVOICE: "sales-invoice",
  PROFORMA_INVOICE: "proforma-invoice",
  PURCHASE_ORDER: "purchase-order",
  SALE_ORDER: "sales-order",
  QUOTATION: "quotation",
  PURCHASE_INVOICE: "purchase-invoice",
  CREDIT_DEBIT_NOTE: "cdn",
  DELIVERY_CHALLAN: "delivery-challan",
  GRN: "grn",
};
