import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IInventory } from "./inventory.types";
import { uploadFile } from "../../helpers/uploadFile";

//=============================== Category List ===============================

export const FETCH_INVENTORY_LIST_PROGRESS = "FETCH_INVENTORY_LIST_PROGRESS";
export const FETCH_INVENTORY_LIST_SUCCESS = "FETCH_INVENTORY_LIST_SUCCESS";
export const FETCH_INVENTORY_LIST_FAILED = "FETCH_INVENTORY_LIST_FAILED";

export const fetchInventoryListProgress = () =>
  action(FETCH_INVENTORY_LIST_PROGRESS);
export const fetchInventoryListSuccess = (
  data: IInventory[],
  totalRecords: number,
) => action(FETCH_INVENTORY_LIST_SUCCESS, { data, totalRecords });
export const fetchInventoryListFailed = () =>
  action(FETCH_INVENTORY_LIST_FAILED);

export const fetchInventoryListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchInventoryListProgress());
      const res = await api.get(
        `inventory/get-inventory${searchQuery}&status=ACTIVE&cancelled_status=TRUE`,
      );
      const data: IInventory[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchInventoryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchInventoryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Get Single Inventory ===============================
export const FETCH_INVENTORY_PROGRESS = "FETCH_INVENTORY_PROGRESS";
export const FETCH_INVENTORY_SUCCESS = "FETCH_INVENTORY_SUCCESS";
export const FETCH_INVENTORY_FAILED = "FETCH_INVENTORY_FAILED";

export const fetchInventoryProgress = () => action(FETCH_INVENTORY_PROGRESS);
export const fetchInventorySuccess = (data: IInventory) =>
  action(FETCH_INVENTORY_SUCCESS, { data });
export const fetchInventoryFailed = (errorMessage: string) =>
  action(FETCH_INVENTORY_FAILED, { errorMessage });

export const fetchInventoryAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchInventoryProgress());
      const res = await api.get(
        `/inventory/get-inventory?inventory_uuid=${uuid}`,
      );
      const data: IInventory[] = res.data.data;
      const { insert_ts, create_ts, ...rest } = data[0];
      if (data.length > 0) {
        dispatch(fetchInventorySuccess(rest));
      } else {
        dispatch(fetchInventoryFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchInventoryFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Inventory ===============================

export const upsertInventoryAsync =
  (
    inventory: IInventory,
    file: any | null,
    onCallback: (isSuccess: boolean, data?: IInventory) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const { create_ts, insert_ts, rowId, ...rest } = inventory;
    try {
      dispatch(saveLoaderProgress());
      let path = "";
      const asPayload = {
        inv_invoice_no: rest.inv_invoice_no,
      };
      path = await uploadFile(
        file,
        "PURCHASE_INVOICE",
        rest.document || "",
        asPayload,
      );
      const res = await api.post("/inventory/upsert_inventory", {
        ...rest,
        document: path,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Inventory saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Delete inventory ===============================
export const deleteInventoryAsync =
  (
    uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.delete("/inventory/delete-inventory", {
        data: { inventory_uuid: uuid },
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Inventory deleted successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Clear States ===============================
export const CLEAR_INVENTORY = "CLEAR_INVENTORY";
export const CLEAR_INVENTORY_STATE = "CLEAR_INVENTORY_STATE";
export const clearInventory = () => action(CLEAR_INVENTORY);
export const clearInventoryState = () => action(CLEAR_INVENTORY_STATE);
